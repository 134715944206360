import * as React from 'react';

import styled from '@emotion/styled';
import Helmet from 'react-helmet';
import { Link } from 'gatsby';
import Container from '../components/Container';
import Page from '../components/Page';
import IndexLayout from '../layouts';
import { TrendsTable } from '../components/TrendsTable';
import { abbreviateNumber } from '../utils/numbers';
import { PageHeader } from '../components/PageHeader';

interface Props {
  pageContext: any;
}

const StyledParagraphBetweenTables = styled.p`
  margin-top: -20px;
`;

export default function SPACsTemplate({ pageContext: { spacs, dailySpacs, renderTime } }: Props) {
  return (
    <IndexLayout>
      <Page>
        <Container>
          <Helmet title="wsbdaily - Where you find Tomorrow’s Hot SPACs Yesterday!" />
          <PageHeader time={renderTime} />
          <h1>Reddit SPAC Investment Trends</h1>
          <p>
            These are the trends on the SPAC communities of Reddit, find more trends on our <Link to="./">homepage</Link>.
          </p>
          <p>
            Top stock tickers popular on the Reddit <a href="https://www.reddit.com/r/SPACs">SPACs</a> community, of{' '}
            {abbreviateNumber(spacs.totalSubscribers)} members, over the last 48-hours.
          </p>
          <TrendsTable data={spacs} postsInsteadOfComments />
          <TrendsTable
            heading={`${dailySpacs.post?.title.startsWith('Weekend') ? 'Weekend' : 'Daily'} Discussion Trends`}
            data={dailySpacs}
          />
        </Container>
      </Page>
    </IndexLayout>
  );
}
